import React, { useRef } from 'react';
import { cn } from '@superside/ui';
import { Box } from '@konsus/superside-kit';
import { useScrollPosition } from '@konsus/hooks';
import { type CardsProps } from '../types';
import { FADE_UP_ANIMATION_DELAY_BETWEEN } from '../utils/useFadeUpAnimation';
import MainCard from './MainCard';
import Card from './Card';
import { BottomVisualAsset } from './TopAndBottomVisualAssets';

export const Cards: React.FC<CardsProps> = ({ mainCard, firstCards, secondCards }) => {
  const cardsWrapperRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useScrollPosition(cardsWrapperRef);

  return (
    <Box
      className={cn(
        '<=768:hide-scrollbar <=768:flex-nowrap 768:gap-6 <=768:mx-0 <=768:my-[-300px] <=768:overflow-scroll <=768:px-0 <=768:py-[300px] snap-x snap-mandatory gap-2',
        isIntersecting && '<768:animate-scroll-wiggle'
      )}
      flex
      direction='row'
      wrap
      ref={cardsWrapperRef}
    >
      <Box
        className={'768:flex-1 768:gap-6 relative z-[2] flex-none snap-center snap-always gap-2'}
      >
        {firstCards.map((card, idx) => (
          <Card key={card._key} {...card} delay={(idx + 1) * FADE_UP_ANIMATION_DELAY_BETWEEN} />
        ))}
      </Box>
      <Box
        className={cn(
          '768:flex-1 768:gap-6 relative z-[2] flex-none snap-center snap-always gap-2',
          '<=768:order-3'
        )}
      >
        {secondCards.map((card, idx) => (
          <Card key={card._key} {...card} delay={(idx + 3) * FADE_UP_ANIMATION_DELAY_BETWEEN} />
        ))}

        <BottomVisualAsset
          visualAsset={mainCard.visualAssets.bottomVisualAsset}
          delay={5 * FADE_UP_ANIMATION_DELAY_BETWEEN}
        />
      </Box>
      <MainCard {...mainCard} delay={6 * FADE_UP_ANIMATION_DELAY_BETWEEN} />
    </Box>
  );
};

export default Cards;
