import React, { useRef } from 'react';
import { cn } from '@superside/ui';
import {
  Box,
  ExternalContentWrapper,
  Text,
  contentWrapperStyle,
  theme
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { NextFutureImage } from '@konsus/image';
import { type CardsColumn } from '../types';
import { useFadeUpAnimation } from '../utils/useFadeUpAnimation';

export const Card: React.FC<CardsColumn> = ({ icon, title, description, delay }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const fadeUpStyles = useFadeUpAnimation(cardRef, delay);

  return (
    <Box
      className={cn(
        '1280:[&_span]:text-bor-lg 1280:m-0 1280:p-8 <768:mx-2 my-0 h-1/2 rounded-lg transition-all duration-[0.5s] ease-[ease-in-out] hover:bg-[#1f3244] hover:transition-all hover:duration-[0.5s] hover:ease-[ease-in-out]',
        ...fadeUpStyles
      )}
      background={theme.colors['space-black']}
      flex
      direction='column'
      pad='medium'
      wrap
      alignContent='center'
      ref={cardRef}
    >
      <Box flex wrap justify='start'>
        <Box direction='row' gap='3' align='center'>
          <Box className={'1280:h-8 1280:w-8 h-6 w-6'}>
            <NextFutureImage image={icon} alt={title} />
          </Box>
          <Box flex wrap>
            {title ? <Text weight='medium'>{title}</Text> : null}
          </Box>
        </Box>

        {description ? (
          <ExternalContentWrapper margin={{ top: '4' }} className={contentWrapperStyle}>
            <SanityBlockComponent blocks={description} />
          </ExternalContentWrapper>
        ) : null}
      </Box>
    </Box>
  );
};

export default Card;
