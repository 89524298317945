import React, { useRef } from 'react';
import { cn, Heading } from '@superside/ui';
import {
  Box,
  ExternalContentWrapper,
  RelativeBox,
  contentWrapperStyle
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { type MainCardProps } from '../types';
import { Superscript } from '../../../components';
import { useFadeUpAnimation } from '../utils/useFadeUpAnimation';
import CaasBgLines from './CaasBgLines';
import { TopVisualAsset } from './TopAndBottomVisualAssets';
import styles from './MainCard.module.css';

export const MainCard: React.FC<MainCardProps> = ({
  superscript,
  title,
  description,
  visualAssets: { topVisualAsset },
  delay
}) => {
  const mainCardRef = useRef<HTMLDivElement>(null);
  const fadeUpStyles = useFadeUpAnimation(mainCardRef, delay);

  return (
    <RelativeBox
      className={cn(
        '768:flex-1 <=768:order-2 <=768:w-[87%] after:animate-opacityAnimation after:content-empty after:rounded-half group relative z-[1] h-fit w-full flex-none snap-center snap-always rounded-lg pb-1 after:absolute after:left-[-14%] after:top-[-14%] after:z-[-2] after:h-[128%] after:w-[128%] after:bg-indigo-600 after:opacity-60 after:blur-[116.129px] after:transition-all after:duration-[3s] after:ease-in-out hover:after:h-[150%] hover:after:w-[150%] hover:after:opacity-100 hover:after:transition-all hover:after:duration-[0.8s] hover:after:ease-in-out',
        ...fadeUpStyles
      )}
      flex
      direction='column'
      ref={mainCardRef}
    >
      <TopVisualAsset visualAsset={topVisualAsset} />
      <CaasBgLines
        className={
          '1280:absolute 1280:animate-scaleAnimation 1280:block 1280:duration-[0.8s] 1280:ease-in-out 1280:left-[-80%] 1280:scale-[0.85]e 1280:top-0 1280:transition-all 1280:z-[-1] hidden group-hover:scale-100 group-hover:transition-all group-hover:duration-200 group-hover:ease-in-out'
        }
      />
      <Box
        className={cn(
          styles.bg,
          'animation-rotating 768:opacity-0 absolute h-full w-full rounded-lg opacity-100 transition-all duration-500 ease-in-out group-hover:opacity-100 group-hover:transition-all group-hover:duration-500 group-hover:ease-in-out'
        )}
      />
      <Box
        className={
          '<768:pb-6 <768:pt-8 <768:px-4 py-15 relative left-0.5 top-0.5 w-[calc(100%_-_4px)] rounded-lg bg-indigo-500 px-8'
        }
      >
        <Superscript content={superscript} />
        <Heading level='h5' as='h2'>
          {title}
        </Heading>
        {description ? (
          <ExternalContentWrapper
            margin={{ top: '4' }}
            className={cn(contentWrapperStyle, 'opacity-80')}
          >
            <SanityBlockComponent blocks={description} />
          </ExternalContentWrapper>
        ) : null}
      </Box>
    </RelativeBox>
  );
};

export default MainCard;
