import React, { useRef } from 'react';
import { cn } from '@superside/ui';
import { Box } from '@konsus/superside-kit';
import { VisualAsset, type VisualAssetProps } from '../../../components';
import { useFadeUpAnimation } from '../utils/useFadeUpAnimation';
import { type BottomVisualAssetProps } from '../types';

export const BottomVisualAsset: React.FC<BottomVisualAssetProps> = ({ visualAsset, delay }) => {
  const bottomVisualAssetRef = useRef<HTMLDivElement>(null);
  const fadeUpStyles = useFadeUpAnimation(bottomVisualAssetRef, delay);

  return (
    <Box
      className={cn(
        'absolute z-[3] [&_div]:h-full [&_div]:w-full',
        '768:bottom-[-50px] 768:left-auto 768:right-[-70px] 768:h-[105px] 768:w-[105px] bottom-[-49px] left-[calc(70px_-_100%)] h-[65px] w-[102px]',
        ...fadeUpStyles
      )}
      ref={bottomVisualAssetRef}
    >
      <VisualAsset
        {...visualAsset}
        objectFit='contain'
        objectPosition='right top'
        requestWidth={210}
      />
    </Box>
  );
};

export const TopVisualAsset: React.FC<{ visualAsset: VisualAssetProps }> = ({ visualAsset }) => {
  return (
    <Box
      className={cn(
        'absolute z-[3] [&_div]:h-full [&_div]:w-full',
        '768:right-4 768:top-[-65px] 768:h-[105px] 768:w-[230px] right-2 top-[-34px] h-[67px] w-[99px]'
      )}
    >
      <VisualAsset
        {...visualAsset}
        objectFit='contain'
        objectPosition='left bottom'
        requestWidth={460}
      />
    </Box>
  );
};
