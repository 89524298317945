import React from 'react';
import { Box } from '@konsus/superside-kit';
import { SectionHeader, PageSection } from '../../components';
import { type CardsSectionProps } from './types';
import Cards from './components/Cards';

export const CardsSection: React.FC<CardsSectionProps> = ({
  header,
  ctaButton,
  mainCard,
  firstCards,
  secondCards,
  paddingTop,
  paddingBottom,
  ...rest
}) => {
  const headerButton =
    header?.ctaButton?.ctaText || header?.ctaButton?.ctaLink ? header.ctaButton : ctaButton;

  return (
    <>
      <PageSection paddingTop={paddingTop} paddingBottom='none' {...rest} className='z-[3]'>
        {header && <SectionHeader {...header} ctaButton={headerButton} flex={true} />}
      </PageSection>
      <Box className={'mx-auto my-[-500px] w-full overflow-hidden px-0 py-[500px]'}>
        <PageSection unlimitedMobile paddingTop='none' paddingBottom={paddingBottom}>
          <Cards mainCard={mainCard} firstCards={firstCards} secondCards={secondCards} />
        </PageSection>
      </Box>
    </>
  );
};

export default CardsSection;
